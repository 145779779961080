import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import { KargoModal } from '../modal/kargo-modal';
import { KargoDoorLabelLiveLook } from './live-look';

export type Direction =
  | 'shipping'
  | 'shipping-highlight'
  | 'receiving'
  | 'receiving-highlight'
  | 'unknown'
  | 'neutral'
  | 'mixed'
  | 'unused'
  | 'FORKLIFT'
  | 'FORKLIFT-HIGHLIGHT';

type Size = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';

const StyledLabel = styled.p`
  ${({ theme: { colors, sizing } }) => `
    color: ${colors.white};
    font-size: ${sizing.scale600};
    font-weight: 800;
    margin-bottom: ${sizing.scale300};
    text-transform: uppercase;
  `}
`;

type StyledBaseBadgeProps = {
  size: Size;
  direction: Direction;
};

const StyledBaseBadge = styled.div<StyledBaseBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1.5px solid;
  border-radius: 3px;
  padding: 0 4px;

  ${({ theme: { sizing, colors }, size, direction }) => css`
    min-width: ${{
      extraSmall: sizing.scale600,
      small: sizing.scale800,
      medium: sizing.scale900,
      large: sizing.scale1200,
      extraLarge: sizing.scale1400,
    }[size]};

    height: ${{
      extraSmall: sizing.scale600,
      small: sizing.scale800,
      medium: sizing.scale900,
      large: sizing.scale1200,
      extraLarge: sizing.scale1400,
    }[size]};

    font-size: ${{
      extraSmall: sizing.scale500,
      small: sizing.scale500,
      medium: sizing.scale600,
      large: sizing.scale700,
      extraLarge: sizing.scale900,
    }[size]};

    font-weight: ${{
      extraSmall: 500,
      small: 600,
      medium: 600,
      large: 600,
      extraLarge: 700,
    }[size]};

    color: ${{
      shipping: colors.dockBadgeShipping,
      'shipping-highlight': colors.dockBadgeShipping,
      receiving: colors.dockBadgeReceiving,
      'receiving-highlight': colors.dockBadgeReceiving,
      mixed: colors.dockBadgeMixed,
      neutral: colors.dockBadgeIdle,
      unknown: colors.dockBadgeUnused,
      unused: colors.dockBadgeUnused,
      FORKLIFT: colors.dockBadgeForklift,
      'FORKLIFT-HIGHLIGHT': colors.dockBadgeForklift,
    }[direction]};

    border-color: ${{
      shipping: colors.dockBadgeShipping,
      'shipping-highlight': colors.dockBadgeShipping,
      receiving: colors.dockBadgeReceiving,
      'receiving-highlight': colors.dockBadgeReceiving,
      mixed: colors.dockBadgeMixed,
      neutral: colors.dockBadgeIdle,
      unknown: colors.dockBadgeUnused,
      unused: colors.dockBadgeUnused,
      FORKLIFT: colors.dockBadgeForklift,
      'FORKLIFT-HIGHLIGHT': colors.dockBadgeForklift,
    }[direction]};
  `}
`;

type StyledBadgeProps = StyledBaseBadgeProps & {
  children: React.ReactNode;
};

const StyledKargoBadge = styled(({ children, ...rest }: StyledBadgeProps) => (
  <StyledBaseBadge {...rest}>{children}</StyledBaseBadge>
))`
  position: absolute;
  top: -15%;
  left: -15%;
`;

const StyledYardBadge = styled(({ children, ...rest }: StyledBadgeProps) => (
  <StyledBaseBadge {...rest}>{children}</StyledBaseBadge>
))`
  position: absolute;
  top: -15%;
  right: -15%;
`;

type StyledDoorNumberProps = {
  size: Size;
};

const StyledDoorNumber = styled.p<StyledDoorNumberProps>`
  line-height: 1;
  white-space: nowrap;

  ${({ theme: { sizing }, size }) => css`
    font-size: ${{
      extraSmall: sizing.scale700,
      small: sizing.scale1000,
      medium: sizing.scale1400,
      large: sizing.scale1500,
      extraLarge: sizing.scale1500,
    }[size]};

    font-weight: ${size
      ? {
          extraSmall: 500,
          small: 200,
          medium: 200,
          large: 200,
          extraLarge: 800,
        }[size]
      : 200};
  `}
`;

const StyledNonLiveViewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLiveViewContentContainer = styled.div`
  display: none;
`;

type StyledContainerProps = {
  size: Size;
  direction: Direction;
  disabled: boolean;
  allowLiveLookView: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ theme: { sizing, colors }, size, direction, disabled }) => css`
    position: relative;
    border-radius: 4px;
    padding: 0 4px;
    width: fit-content;

    min-width: ${{
      extraSmall: sizing.scale1300,
      small: sizing.scale2000,
      medium: sizing.scale3000,
      large: sizing.scale3000,
      extraLarge: sizing.scale4000,
    }[size]};

    height: ${{
      extraSmall: sizing.scale1300,
      small: sizing.scale2000,
      medium: sizing.scale2100,
      large: sizing.scale3000,
      extraLarge: sizing.scale4000,
    }[size]};

    background: ${disabled
      ? colors.doorIdle
      : {
          shipping: colors.doorShippingBackground,
          'shipping-highlight': colors.doorShippingBackgroundHighlight,
          receiving: colors.doorReceivingBackground,
          'receiving-highlight': colors.doorReceivingBackgroundHighlight,
          mixed: colors.doorNeutralBackground,
          neutral: colors.doorNeutralBackground,
          unknown: colors.doorUnusedBackground,
          unused: colors.doorUnusedBackground,
          FORKLIFT: colors.doorForkliftBackground,
          'FORKLIFT-HIGHLIGHT': colors.doorForkliftBackgroundHighlight,
        }[direction]};

    color: ${{
      shipping: colors.doorShippingColor,
      'shipping-highlight': colors.doorShippingColorBlue,
      receiving: colors.doorReceivingColor,
      'receiving-highlight': colors.doorReceivingColorGreen,
      mixed: colors.doorNeutralColor,
      unknown: colors.doorNeutralColor,
      neutral: colors.doorNeutralColor,
      unused: colors.doorUnusedColor,
      FORKLIFT: colors.doorForkliftColor,
      'FORKLIFT-HIGHLIGHT': colors.doorForkliftBackground,
    }[direction]};
  `}

  ${(p) =>
    p.allowLiveLookView &&
    css`
      :hover {
        ${StyledNonLiveViewContentContainer} {
          display: none;
        }

        ${StyledLiveViewContentContainer} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          gap: 4px;
        }
      }
    `}
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 400;
`;

const StyledLiveLookCameraShapesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

type StyledLiveLookCameraSquareProps = {
  size: Size;
};

const StyledLiveLookCameraSquare = styled.div<StyledLiveLookCameraSquareProps>`
  height: 14px;
  width: 18px;
  border-radius: 3px;
  background-color: ${(p) => p.theme.colors.white};

  ${({ size }) => css`
    height: ${{
      extraSmall: 10,
      small: 12,
      medium: 14,
      large: 16,
      extraLarge: 20,
    }[size]}px;

    width: ${{
      extraSmall: 14,
      small: 15,
      medium: 18,
      large: 20,
      extraLarge: 22,
    }[size]}px;
  `}
`;

type StyledLiveLookCameraTrapezoidProps = {
  size: Size;
};

const StyledLiveLookCameraTrapezoid = styled.div<StyledLiveLookCameraTrapezoidProps>`
  border-radius: 1px;

  ${({ theme: { colors }, size }) => css`
    height: ${{
      extraSmall: 10,
      small: 10,
      medium: 14,
      large: 16,
      extraLarge: 20,
    }[size]}px;

    border-right: ${{
      extraSmall: `3px solid ${colors.white}`,
      small: `4px solid ${colors.white}`,
      medium: `5px solid ${colors.white}`,
      large: `6px solid ${colors.white}`,
      extraLarge: `7px solid ${colors.white}`,
    }[size]};

    border-top: ${{
      extraSmall: '1px solid transparent',
      small: '3px solid transparent',
      medium: '3px solid transparent',
      large: '4px solid transparent',
      extraLarge: '5px solid transparent',
    }[size]};

    border-bottom: ${{
      extraSmall: '1px solid transparent',
      small: '3px solid transparent',
      medium: '3px solid transparent',
      large: '4px solid transparent',
      extraLarge: '5px solid transparent',
    }[size]};
  `}
`;

type StyledLiveLookTextProps = {
  size: Size;
};

const StyledLiveLookText = styled.p<StyledLiveLookTextProps>`
  text-transform: uppercase;

  ${({ size }) => css`
    font-size: ${{
      extraSmall: 0.375,
      small: 0.375,
      medium: 0.5,
      large: 0.75,
      extraLarge: 0.75,
    }[size]}rem;
  `}
`;

const StyledLiveLookModal = styled(KargoModal)`
  & .door-label__live-look-modal-paper {
    background-color: ${(p) => p.theme.colors.backgroundPrimary};
  }
`;

type Props = {
  dockId?: number;
  direction: Direction;
  size?: Size;
  name?: string;
  isKargoDoor?: boolean;
  isParkingDoor?: boolean;
  towerVersion?: string;
  disabled?: boolean;
  disabledLiveLook?: boolean;
};

const DoorLabel = ({
  name,
  dockId,
  direction = 'neutral',
  size = 'medium',
  isKargoDoor,
  isParkingDoor,
  towerVersion,
  disabled = false,
  disabledLiveLook = false,
}: Props) => {
  const [showLiveLookModal, setShowLiveLookModal] = useState<boolean>(false);

  const allowLiveLookView =
    !disabledLiveLook && !!isKargoDoor && name !== undefined;
  const onLiveLookLabelClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      setShowLiveLookModal(true);
    },
    [],
  );

  return (
    <>
      <StyledContainer
        direction={direction}
        size={size}
        disabled={disabled}
        allowLiveLookView={allowLiveLookView}
      >
        {isKargoDoor && (
          <StyledKargoBadge direction={direction} size={size}>
            K{towerVersion}
          </StyledKargoBadge>
        )}

        {isParkingDoor && (
          <StyledYardBadge direction={direction} size={size}>
            Y
          </StyledYardBadge>
        )}

        <StyledContentContainer>
          <StyledNonLiveViewContentContainer>
            {size === 'extraLarge' && <StyledLabel>Door</StyledLabel>}

            <StyledDoorNumber size={size}>{name ?? '—'}</StyledDoorNumber>
          </StyledNonLiveViewContentContainer>

          <StyledLiveViewContentContainer onClick={onLiveLookLabelClick}>
            <StyledLiveLookCameraShapesContainer>
              <StyledLiveLookCameraSquare size={size} />
              <StyledLiveLookCameraTrapezoid size={size} />
            </StyledLiveLookCameraShapesContainer>

            <StyledLiveLookText size={size}>Live Look</StyledLiveLookText>
          </StyledLiveViewContentContainer>
        </StyledContentContainer>
      </StyledContainer>

      {allowLiveLookView && (
        <StyledLiveLookModal
          onClick={(e) => e.preventDefault()}
          open={showLiveLookModal}
          fullWidth
          maxWidth='xl'
          classes={{
            paper: 'door-label__live-look-modal-paper',
          }}
          onClose={() => {
            setShowLiveLookModal(false);
          }}
        >
          {dockId !== undefined && <KargoDoorLabelLiveLook dockId={dockId} />}
        </StyledLiveLookModal>
      )}
    </>
  );
};

export default DoorLabel;
